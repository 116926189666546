import * as React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXProvider } from "@mdx-js/react";

const BlogPost = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image);
  return (
    <Layout pageTitle={data.mdx.frontmatter.title} flex={true}>
      <div className="lg:mx-64">
        <div className="p-7">
          <Link to="/blog" className="text-cyan-500">
            <i className="fa fa-arrow-left fa-lg" /> <span>View All Blogs</span>
          </Link>
        </div>
        <div className="w-full text-center mx-auto">
          <GatsbyImage
            image={image}
            alt={data.mdx.frontmatter.hero_image_alt}
            className="rounded-md w-[330px] h-[225px] lg:w-[690px] lg:h-[475px]"
          />

          <p className="text-3xl font-bold text-gray-100 my-5">
            {data.mdx.frontmatter.title}
          </p>
          <div className="flex flex-row justify-center">
            <p className="mx-2 text-cyan-500">
              {data.mdx.frontmatter.category}
            </p>
            <p className="mx-2 text-gray-600">{data.mdx.frontmatter.date}</p>
          </div>
          <ul className="flex flex-row flex-wrap justify-center my-5">
            {data.mdx.frontmatter.tags.map((tag) => (
              <li
                key={tag}
                className="py-1 px-3 mx-1 rounded bg-cyan-200 mb-3 text-cyan-900 text-xs font-medium tracking-widest"
              >
                {tag}
              </li>
            ))}
          </ul>
        </div>
        <div className="my-5 px-3">
          <MDXProvider
            components={{
              h1: (props) => (
                <h1 className="text-3xl font-extrabold py-2">
                  {props.children}
                </h1>
              ),
              h2: (props) => (
                <h2 className="text-2xl font-extrabold pb-1 text-cyan-500">
                  {props.children}
                </h2>
              ),
              h3: (props) => (
                <h3 className="text-xl font-extrabold py-2">
                  {props.children}
                </h3>
              ),
              h4: (props) => (
                <h4 className="text-base font-extrabold py-1 underline decoration-2 decoration-sky-500 underline-offset-4">
                  {props.children}
                </h4>
              ),
              h5: (props) => (
                <h5 className="text-base font-bold">{props.children}</h5>
              ),
              h6: (props) => (
                <h6 className="text-base font-bold py-5 text-yellow-500">
                  {props.children}
                </h6>
              ),
              p: (props) => <p {...props} className="pb-5 text-gray-100" />,
              table: (props) => (
                <table
                  {...props}
                  className="py-3 mx-auto table-auto border-collapse border border-slate-400"
                >
                  {props.children}
                </table>
              ),
              th: (props) => (
                <th className="border p-2 border-slate-300">
                  {props.children}
                </th>
              ),
              td: (props) => (
                <td className="border p-2 text-base border-slate-300">
                  {props.children}
                </td>
              ),
              ul: (props) => (
                <ul className="mb-3 list-disc list-inside">{props.children}</ul>
              ),
              ol: (props) => (
                <ol className="mb-3 list-decimal list-inside">
                  {props.children}
                </ol>
              ),
              blockquote: (props) => (
                <blockquote className="indent-10">{props.children}</blockquote>
              ),
              a: (props) => (
                <a
                  className="text-cyan-300 cursor-pointer"
                  href={props.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.children}
                </a>
              ),
              img: (props) => {
                console.log(props);
                return (
                  <img className="mx-auto" src={props.src} alt={props.alt} />
                );
              },
            }}
          >
            <MDXRenderer className="text-left">{data.mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </Layout>
  );
};

// this is getting ALL the posts. I only want one
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        category
        tags
        desc
        title
        date(formatString: "MMMM DD, YYYY")
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default BlogPost;
